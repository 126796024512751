<template>
	<!--서비스 상세 페이지 - 암호-->
	<div class="Cycontainer" v-if="computedValue === `ko`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2>보안체계를 만나 더 완벽해진</h2>
					<h1>암호 스키마</h1>
					<p>
						암호 스키마에 대한 원천기술 개발과 특허권을<br />
						보유하고 있는 FCTS만의 암호화 서비스입니다.<br />
						높은 자유도로 업무환경의 질을 높이고 다양한<br />
						환경에 적용 및 API를 통한 라이브러리를 제공합니다.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">지금 문의하기</button>
						</router-link>
						<router-link to="/solutioncrypto">
							<button class="btn--md btn--fill-gray">솔루션 이동하기</button>
						</router-link>
					</div>
				</div>
				<!-- service crypto main benner end-->
				<!--header img-->
				<div class="servicecontent__mainimg">
					<img :src="cryptomain" />
				</div>
			</div>
			<!-- content1 end -->

			<!-- content2 -->
			<div id="content2">
				<div class="servicecontent__wrapper--imgbg">
					<img :src="cryptoBg" />
					<div class="servicecontent__textbox">
						<h1>편리하고 유용한</h1>
						<div class="servicecontent_stroke"></div>
						<h2>FCTS 암호 스키마</h2>
						<p>
							자사 자체 개발로 암호 스키마의 원천기술 보유<br />뛰어난 보안력과
							높은 자유도
						</p>
					</div>
				</div>
				<div class="servicecontent2_box">
					<div class="servicecontent2_textbox">
						<h1>높은 보안력을 가진 <span>암호 스키마</span></h1>
						<div class="border-graient"></div>
						<p>
							FCTS는 원천기술 개발을 통하여 암호 스키마 서비스를 제공합니다.<br />
							동형 암호 구조(Homomorphic Encryption Structure)를 <br />
							가지는 암호 스키마로서 동형 분석과 데이터 압축 등에<br />
							다양한 분야에서 활용 가능한 서비스입니다.
						</p>
					</div>
				</div>
			</div>
			<!--content2 end-->

			<!--content3-->
			<div id="content3">
				<div class="content3-title_bg"></div>
				<div class="content3-title">
					<h1>다양한 환경에서 쉽게 활용</h1>
					<p>
						유연하고 높은 자유도로 어느 환경이든<br />
						개발자가 쉽게 활용할 수 있는 암호 스키마 서비스!
					</p>
				</div>
				<div class="content3_box">
					<img :src="content3img" />
				</div>
			</div>

			<!--content4-->
			<div id="content4">
				<div class="content4-title">
					<p>Crypto Schema Detail Service</p>
					<h2>암호 스키마 상세 서비스</h2>
				</div>
				<div class="content4_box">
					<div class="content_box">
						<div class="img_box1"><img :src="mfcImg" /></div>
						<div class="text_box">
							<h2>MFC</h2>
							<h3>Modular Form Cryptosystem</h3>
							<div class="border"></div>
							<p>
								Modular form을 통하여 ECC의 보안력을 향상한 암호 스키마 MFC
								입니다.<br />
								RSA, ECC에 비하여 짧은 키 갈이와 빠른 연산속도를 가지면서 동일한
								수준의<br />보안 강도를 제공할 수 있고 전자서명, 키 관리 등에
								활용할 수 있습니다.
							</p>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>ECHC</h2>
							<h3>Elliptic Curve<br />Homomorphic Cryptosystem</h3>
							<div class="border"></div>
							<p>
								Elliptic Curve 위의 동형 암호 구조를 적용한 암호 스키마로서<br />
								ECC의 경량성을 동형 암호 구조에 접목하여 기존 동형 암호의
								속도<br />
								측면을 개선한 암호 스키마입니다. 동형 분석 등과 같이 암호화를<br />
								통한 분석 방법에 활용할 수 있습니다.
							</p>
						</div>
						<div class="img_box2"><img :src="echcImg" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="phcImg" /></div>

						<div class="text_box">
							<h2>PHC</h2>
							<h3>Pseudo Homomorphic Cryptosystem</h3>
							<div class="border"></div>
							<p>
								PHC는 MFC와 ECHC를 통합하여 만든 새로운 암호 스키마입니다.<br />MFC와
								ECHC에 비하여 속도 측면은 조금 느리지만 보안력은 월등히<br />향상된
								암호 스키마 서비스입니다. 강력한 데이터 보호가 필요한 범용적<br />
								환경에 활용할 수 있습니다.
							</p>
						</div>
					</div>
				</div>
			</div>
			<!--content4 end-->
			<div id="content5Cy">
				<!--content5 title-->
				<div class="content5cy-title">
					<p>Utilization by industry</p>
					<h2>산업별 활용 사례</h2>
				</div>
				<!--content5_box-->
				<div class="content5_box">
					<!--box1-->
					<div class="box1">
						<div class="box_title">
							<h1>01</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>의료기관</h2>
							<p>DB 암호화 및 LIS 구축</p>
						</div>
					</div>
					<!--box1 end-->
					<!--box2-->
					<div class="box2">
						<div class="box_title">
							<h1>02</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>분석기관</h2>
							<p>Data 암호화를 통한<br />동형 분석 시스템 구축</p>
						</div>
					</div>
					<!--box2 end-->
					<!--box3-->
					<div class="box3">
						<div class="box_title">
							<h1>03</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>금융기관</h2>
							<p>Data 암호화를 통한<br />금융거래 시스템 구축</p>
						</div>
					</div>
				</div>
				<!--box3 end-->
				<!--content5_box end-->
			</div>
			<!--content5 end-->
			<!--컨텐츠 6-->
			<div id="content6">
				<SwiperKr />
			</div>
			<!--content6 end-->

			<div class="mb__Cont4">
				<div class="TopText_mb">요금이 궁금하신가요?</div>
				<div class="BtmText_mb">
					내 비지니스 유형에 적합한 플랜을 찾아보세요!
				</div>
				<router-link to="/pricingsecurity" class="mb-price-button">
					모듈러스 요금 확인
				</router-link>
				<router-link to="/pricingbimining" class="mb-price-button">
					BI Mining 요금 확인
				</router-link>
				<div class="TopText_mb">솔루션이 궁금하신가요?</div>
				<div class="BtmText_mb">
					내 비지니스 유형에 적합한 솔루션을 찾아보세요!
				</div>
				<router-link to="/solutioncrypto" class="mb-solution-button">
					암호스키마 솔루션 바로가기
				</router-link>
				<router-link to="/solutionvaccine" class="mb-solution-button">
					FC-Anti Virus 솔루션 바로가기
				</router-link>
				<router-link to="/" class="mb-solution-button">
					CryptaLedger Tech<br />
					솔루션 바로가기
				</router-link>
			</div>
			<!--content7-->
			<!-- <div class="servicecontent__wrapper center bg contents7">
				<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
				<p>
					필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
					지금 바로 진단페이지를 통해 제품을 받아보세요!
				</p>
				<div>
					<button class="btn--md btn--fill-accent">
						<router-link to="/warning">진단 페이지</router-link></button
					><button class="btn--md btn--fill-gray">
						<router-link to="/inquiry">문의 하기</router-link>
					</button>
				</div>
			</div> -->
		</div>
	</div>
	<div class="Cycontainer" v-else-if="computedValue === `en`">
		<!--content1-->
		<!-- service crypto main benner-->
		<div id="content1">
			<div class="servicecontent__wrapper--row bg">
				<div class="servicecontent__header">
					<!--header text-->
					<h2>Meet the Enhanced Security System</h2>
					<h1>Crypto Schema</h1>
					<p>
						This is FCTS's exclusive Crypto System service, developed with<br />
						proprietary Crypto schema technology and backed by patents.<br />
						It enhances the quality of work environments with high<br />
						flexibility, offers compatibility across various environments,
						and<br />
						provides libraries via API integration.
					</p>
					<!--header button-->
					<div class="servicecontent__btn">
						<router-link to="/inquiry">
							<button class="btn--md btn--fill-accent">Contact Us</button>
						</router-link>
						<router-link to="/solutioncrypto">
							<button class="btn--md btn--fill-gray">Go to Solution</button>
						</router-link>
					</div>
				</div>
				<!-- service crypto main benner end-->
				<!--header img-->
				<div class="servicecontent__mainimg">
					<img :src="cryptomain" />
				</div>
			</div>
			<!-- content1 end -->

			<!-- content2 -->
			<div id="content2">
				<div class="servicecontent__wrapper--imgbg">
					<img :src="cryptoBg" />
					<div class="servicecontent__textbox">
						<h1>Convenient and Useful</h1>
						<div class="servicecontent_stroke"></div>
						<h2>FCTS Crypto Schema</h2>
						<p>
							We hold the core technology of the Crypto Schema<br />through our
							in-house development,<br />offering excellent security and high
							flexibility.
						</p>
					</div>
				</div>
				<div class="servicecontent2_box">
					<div class="servicecontent2_textbox">
						<h1><span>Crypto Schema</span> with High Security</h1>
						<div class="border-graient"></div>
						<p>
							FCTS provides a Crypto Schema service through<br />
							the development of core technologies.<br />
							As a Crypto Schema with a Homomorphic Encryption Structure,<br />
							it is a versatile service that can be utilized in various
							fields,<br />
							such as homomorphic analysis and data compression
						</p>
					</div>
				</div>
			</div>
			<!--content2 end-->

			<!--content3-->
			<div id="content3">
				<div class="content3-title_bg"></div>
				<div class="content3-title">
					<h1>Easily utilized in various environments</h1>
					<p>
						A flexible Crypto Schema service with high adaptability,<br />
						allowing developers to easily utilize it in any environment!
					</p>
				</div>
				<div class="content3_box">
					<img :src="content3imgen" />
				</div>
			</div>

			<!--content4-->
			<div id="content4">
				<div class="content4-title">
					<h2>Crypto Schema Detail Service</h2>
				</div>
				<div class="content4_box">
					<div class="content_box">
						<div class="img_box1"><img :src="mfcImg" /></div>
						<div class="text_box">
							<h2>MFC</h2>
							<h3>Modular Form Cryptosystem</h3>
							<div class="border"></div>
							<p>
								This is the MFC Crypto Schema, which enhances the<br />
								security of ECC through a modular form.<br />It offers shorter
								key lengths and faster computation<br />speeds compared to RSA
								and ECC while providing<br />the same level of security
								strength.<br />It can be utilized in applications such as<br />digital
								signatures and key management.
							</p>
						</div>
					</div>

					<div class="content_box content_box2">
						<div class="text_box-right">
							<h2>ECHC</h2>
							<h3>Elliptic Curve<br />Homomorphic Cryptosystem</h3>
							<div class="border"></div>
							<p>
								This is a Crypto Schema that applies the homomorphic<br />
								encryption structure based on Elliptic Curve.<br />
								By integrating the lightweight characteristics of ECC<br />
								into the homomorphic encryption structure, it improves<br />
								the speed aspects of traditional homomorphic encryption.<br />
								It can be utilized in analytical methods through<br />encryption,
								such as homomorphic analysis.
							</p>
						</div>
						<div class="img_box2"><img :src="echcImg" /></div>
					</div>

					<div class="content_box">
						<div class="img_box1"><img :src="phcImg" /></div>

						<div class="text_box">
							<h2>PHC</h2>
							<h3>Pseudo Homomorphic Cryptosystem</h3>
							<div class="border"></div>
							<p>
								PHC is a new Crypto Schema created by integrating MFC and
								ECHC.<br />Although it is slightly slower in terms of speed
								compared to<br />MFC and ECHC, it offers significantly enhanced
								security.<br />
								This Crypto Schema service can be utilized in universal<br />environments
								that require robust data protection.
							</p>
						</div>
					</div>
				</div>
			</div>
			<!--content4 end-->
			<div id="content5Cy">
				<!--content5 title-->
				<div class="content5cy-title">
					<h2>Utilization by industry</h2>
				</div>
				<!--content5_box-->
				<div class="content5_box">
					<!--box1-->
					<div class="box1">
						<div class="box_title">
							<h1>01</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>Medical Institutions</h2>
							<p>DB encryption and LIS construction</p>
						</div>
					</div>
					<!--box1 end-->
					<!--box2-->
					<div class="box2">
						<div class="box_title">
							<h1>02</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>Analysis Institutions</h2>
							<p>
								Establishing a homomorphic analysis<br />system through data
								encryption
							</p>
						</div>
					</div>
					<!--box2 end-->
					<!--box3-->
					<div class="box3">
						<div class="box_title">
							<h1>03</h1>
							<div class="circle"></div>
						</div>
						<div class="box_subtitle">
							<h2>Financial Institutions</h2>
							<p>
								Establishing a financial transaction system<br />through data
								encryption
							</p>
						</div>
					</div>
				</div>
				<!--box3 end-->
				<!--content5_box end-->
			</div>
			<!--content5 end-->
			<!--컨텐츠 6-->
			<div id="content6">
				<SwiperKr />
			</div>
			<!--content6 end-->

			<div class="mb__Cont4">
				<div class="TopText_mb">Curious about the pricing?</div>
				<div class="BtmText_mb">
					Find the plan that's right for your business type!
				</div>
				<router-link to="/pricingsecurity" class="mb-price-button">
					Modulus Check Pricing
				</router-link>
				<router-link to="/pricingbimining" class="mb-price-button">
					BI Mining Check Pricing
				</router-link>
				<div class="TopText_mb">Curious about the solution?</div>
				<div class="BtmText_mb">
					Find the plan that's right for your business type!
				</div>
				<router-link to="/solutioncrypto" class="mb-solution-button">
					Crypto Schema Go to Solution
				</router-link>
				<router-link to="/solutionvaccine" class="mb-solution-button">
					FC-Anti Virus Go to Solution
				</router-link>
				<router-link to="/" class="mb-solution-button">
					CryptaLedger Tech <br />Go to Solution
				</router-link>
			</div>
			<!--content7-->
			<!-- <div class="servicecontent__wrapper center bg contents7">
				<h1>업무 개발 환경에 맞춰 추천 하는 서비스!</h1>
				<p>
					필요한 제품을 확인하고 개발 환경에 맞추어 추천 하는 서비스 입니다!<br />
					지금 바로 진단페이지를 통해 제품을 받아보세요!
				</p>
				<div>
					<button class="btn--md btn--fill-accent">
						<router-link to="/warning">진단 페이지</router-link></button
					><button class="btn--md btn--fill-gray">
						<router-link to="/inquiry">문의 하기</router-link>
					</button>
				</div>
			</div> -->
		</div>
	</div>
	<!--container end-->
</template>

<script>
import cryptoBg from '../assets/images/service/cryptobg3.png';
import speciality1 from '../assets/images/service/speciality1.svg';
import speciality2 from '../assets/images/service/speciality2.svg';
import speciality3 from '../assets/images/service/speciality3.svg';
import cryptomain from '../assets/images/service/cryptomain.svg';
import content3img from '../assets/images/service/servicecrypto-content3.svg';
import content3imgen from '../assets/images/service/servicecrypto-content3en.svg';
import mfcImg from '../assets/images/service/crypto-mfc.svg';
import echcImg from '../assets/images/service/crypto-echc.svg';
import phcImg from '../assets/images/service/crypto-phc.svg';

export default {
	data() {
		return {
			service: 1,
			mfcImg,
			echcImg,
			phcImg,
			cryptoBg,
			speciality1,
			speciality2,
			speciality3,
			cryptomain,
			content3img,
			content3imgen,
		};
	},
	computed: {
		computedValue() {
			return this.$store.state.language;
		},
	},

	methods: {
		showService1() {
			this.service = 1;
		},
		showService2() {
			this.service = 2;
		},
		showService3() {
			this.service = 3;
		},
		showService4() {
			this.service = 4;
		},
		showService5() {
			this.service = 5;
		},
	},
};
</script>

<style></style>
